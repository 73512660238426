/***
 * each time a new url is added to the router
 * need to be added need to be added here
 * and as a constant and also in the EXCLUDE_ULS_404 array
 */
import {
  COME_BACK_5,
  DELAY_EXPIRED,
  FOREVER_35,
  JC_AUG_V1,
  partnerIFrames,
  SOMETHING,
  SUBSCRIPTION_DELAY
} from './landing'
import { normalizePath } from '../../utils/helpers'
import { QUESTIONNAIRE_URIS } from './questionnaire'
import { pdpUrls } from '../endpoints'

const partnerIFramesUrl = partnerIFrames.map(pif => pif?.path)

export const HOME_URL = '/'
export const AUTH_USER_PASSWORDLESS_URL = '/auth/user/passwordless'
export const AUTH_USER_URL = 'auth/user'
export const AMP_AUTH_USER_URL = '/amp/auth/user'
export const ACCOUNT_URL = '/account'
export const PAGES_URL = '/pages'
export const CHECKOUT_URL = '/checkout'
export const ADMIN_URL = '/admin'
export const WHY_PROVEN_URL = '/why-proven'
export const FAQS_URL = '/faqs'
export const ABOUT_US_URL = '/about-us'
export const CONTACT_US_URL = '/contact-us'
export const REVIEWS_URL = '/reviews'
export const PRESS_URL = '/press'
export const WELCOME_BACK_URL = '/welcome-back'
export const WELCOME_BACK_EMAIL_URL = '/welcome-back-email'
export const LOGIN_URL = '/login'
export const LOGIN_URL_EMAIL = '/login-email'
export const LOGIN_URL_EMAIL2 = '/login-email2'
export const BLOG_URL = '/blog'
export const BUILDER_PREVIEW_URL = '/__builder__/preview'
export const CLINICAL_PROOF_URL = '/clinical-proof'
export const GIFT_REDEEM_SUCCESS_NEW_URL = '/gift-redeem/success/new'
export const GIFT_REDEEM_SUCCESS_EXISTING_URL = '/gift-redeem/success/existing'
export const GIFT_REDEEM_URL = '/gift-redeem'
export const SCIENCE_URL = '/science'
export const MULBERRY_URL = '/mulberry'
export const OFFER_URL = '/offer'
export const TRIAL_URL = '/trial'
export const SCIENCE_PROMO_SCIENCE_URL = '/science/$promo=SCIENCE'
export const PERSONALIZED_CONCERNS_URL = '/personalized-concerns'
export const SKIMMERS_URL = '/skimmers'
export const DIGITALDERM_URL = '/digitalderm'
export const ALL_ABOUT_YOU_URL = '/all-about-you'
export const EMAILUNSUBSCRIBESUCCESSFUL_URL = '/emailunsubscribesuccessful'
export const REGAEMAILUNSUBSCRIBESUCCESSFULL_URL = '/regaemailunsubscribesuccessful'
export const MAINTENANCE_URL = '/maintenance'
export const NOT_FOUND_URL = '/not-found'
export const TERMS_AND_CONDITIONS_URL = '/terms-and-conditions/'
export const PRIVACY_POLICY_URL = '/privacy-policy/'
export const SUBSCRIPTION_TERMS_URL = '/subscription-terms/'
export const COOKIE_POLICY_URL = '/cookie-policy/'
export const ACCOUNT_PERSONALIZE = '/account/skin'
export const ACCOUNT_PREFERENCES = '/account/skin/preferences'
export const ACCOUNT_FEEDBACK = '/account/skin/feedback'
export const ACCOUNT_SUBSCRIPTIONS = '/account/subscriptions'
export const ACCOUNT_SHOP = '/account/shop'
export const ACCOUNT_CHECKOUT = '/account/checkout'
export const ACCOUNT_THANK_YOU = '/account/thank-you'
export const ACCOUNT_SETTINGS_INFO = '/account/settings/info'
export const ACCOUNT_SETTINGS_CREDITS = '/account/settings/credits'
export const ACCOUNT_SETTINGS_ORDERS = '/account/settings/orders'
export const ACCOUNT_SUPPORT = '/account/support'
export const ACCOUNT_SUPPORT_QUESTIONS = '/account/support/questions'
export const ACCOUNT_SHOP_ = '/account/shop/onetime/mini-cryo-roller'
export const ACCOUNT_MAKEUP_REMOVING_ROUNDS = '/account/shop/onetime/makeup-removing-rounds'
export const ACCOUNT_MINI_CRYO_ROLLER = '/account/shop/onetime/mini-cryo-roller'
export const ACCOUNT_HEALING_DOTS_ACNE_PATCH_4PK =
  '/account/shop/onetime/healing-dots-acne-patch-4pk'
export const ACCOUNT_CRYO_ROLLER = '/account/shop/onetime/cryo-roller'
export const ACCOUNT_ACNE_SPOT_TREATMENT = '/account/shop/onetime/acne-spot-treatment'
export const ACCOUNT_PORE_PURIFYING_PAD = '/account/shop/onetime/pore-purifying-pad'

export const ACCOUNT_CONGRATULATIONS = '/account/congratulations'
export const ACCOUNT_CONGRATULATIONS_EYE = '/account/congratulations/eye'
export const SKIN_QUIZ_OPENING_PAGE_URL = '/quiz/start'
export const EYE_QUIZ_OPENING_PAGE_URL = '/quiz/eye/start'
export const SERUM_QUIZ_OPENING_PAGE_URL = '/quiz/serum/start'

export const EXCLUDE_URLS_404_PRIVATE_ROUTES_OR_EXCEPTION = [ACCOUNT_URL, ADMIN_URL, BLOG_URL]

const EXCLUDE_ULS_404_WITH_LEADING = [
  ...QUESTIONNAIRE_URIS,
  HOME_URL,
  AUTH_USER_PASSWORDLESS_URL,
  AUTH_USER_URL,
  AMP_AUTH_USER_URL,
  PAGES_URL,
  CHECKOUT_URL,
  WHY_PROVEN_URL,
  FAQS_URL,
  ABOUT_US_URL,
  CONTACT_US_URL,
  REVIEWS_URL,
  PRESS_URL,
  WELCOME_BACK_URL,
  WELCOME_BACK_EMAIL_URL,
  LOGIN_URL,
  LOGIN_URL_EMAIL,
  LOGIN_URL_EMAIL2,
  BLOG_URL,
  BUILDER_PREVIEW_URL,
  CLINICAL_PROOF_URL,
  GIFT_REDEEM_SUCCESS_NEW_URL,
  GIFT_REDEEM_SUCCESS_EXISTING_URL,
  GIFT_REDEEM_URL,
  SCIENCE_URL,
  MULBERRY_URL,
  OFFER_URL,
  TRIAL_URL,
  SCIENCE_PROMO_SCIENCE_URL,
  SUBSCRIPTION_DELAY,
  DELAY_EXPIRED,
  JC_AUG_V1,
  COME_BACK_5,
  SOMETHING,
  FOREVER_35,
  PERSONALIZED_CONCERNS_URL,
  SKIMMERS_URL,
  DIGITALDERM_URL,
  ALL_ABOUT_YOU_URL,
  EMAILUNSUBSCRIBESUCCESSFUL_URL,
  REGAEMAILUNSUBSCRIBESUCCESSFULL_URL,
  MAINTENANCE_URL,
  NOT_FOUND_URL,
  ...pdpUrls, //if merge conflict keep the pdpUrls defined at /endpoints.js
  ...partnerIFramesUrl,
  TERMS_AND_CONDITIONS_URL,
  PRIVACY_POLICY_URL,
  SUBSCRIPTION_TERMS_URL,
  COOKIE_POLICY_URL,
  ACCOUNT_PERSONALIZE,
  ACCOUNT_PREFERENCES,
  ACCOUNT_FEEDBACK,
  ACCOUNT_SUBSCRIPTIONS,
  ACCOUNT_SHOP,
  ACCOUNT_CHECKOUT,
  ACCOUNT_THANK_YOU,
  ACCOUNT_SETTINGS_INFO,
  ACCOUNT_SETTINGS_CREDITS,
  ACCOUNT_SETTINGS_ORDERS,
  ACCOUNT_SUPPORT,
  ACCOUNT_SUPPORT_QUESTIONS,
  ACCOUNT_SHOP_,
  ACCOUNT_MAKEUP_REMOVING_ROUNDS,
  ACCOUNT_MINI_CRYO_ROLLER,
  ACCOUNT_HEALING_DOTS_ACNE_PATCH_4PK,
  ACCOUNT_CRYO_ROLLER,
  ACCOUNT_ACNE_SPOT_TREATMENT,
  ACCOUNT_PORE_PURIFYING_PAD,
  ACCOUNT_CONGRATULATIONS_EYE,
  ACCOUNT_CONGRATULATIONS,
  ACCOUNT_URL,
  ADMIN_URL,
  SKIN_QUIZ_OPENING_PAGE_URL,
  EYE_QUIZ_OPENING_PAGE_URL,
  SERUM_QUIZ_OPENING_PAGE_URL
]

const EXCLUDE_ULS_404_WITHOUT_LEADING = EXCLUDE_ULS_404_WITH_LEADING.map(url => {
  return normalizePath(url)
})
export const EXCLUDE_ULS_404 = [...EXCLUDE_ULS_404_WITH_LEADING, ...EXCLUDE_ULS_404_WITHOUT_LEADING]

export const EXCLUDE_URL_FOR_REDIRECT = [
  '/dist/[object%20Module]/',
  '/dist/%5Bobject%20Module%5D',
  '/dist/%5Bobject%20Module%5D'
]
