export const REDUX_FORM_OPTIONS = {
  enableReinitialize: true,
  destroyOnUnmount: false
}

export const APP_EDITION_PROVEN = 'proven'
export const APP_EDITION_SEPHORA = 'sephora'
export const APP_EDITION = process.env.APP_EDITION || APP_EDITION_PROVEN

// eslint-disable-next-line no-console
console.log('process.env.APP_EDITION', process.env.APP_EDITION)
// eslint-disable-next-line no-console
console.log('APP_EDITION', APP_EDITION)

export const BUILDER_API_KEY = process.env.BUILDER_ENV_API_KEY || '41522f1b6cf543838120df677d7a6868'
//export const BUILDER_API_KEY = process.env.BUILDER_ENV_API_KEY || '9e1c9500f106432dbfa7f5743e4868e1'

export const FORCE_SEARCH_PAGE_ON_STRAPI = process.env.FORCE_SEARCH_PAGE_ON_STRAPI
  ? process.env.FORCE_SEARCH_PAGE_ON_STRAPI === 'true'
  : false

export const BUILDER_RESOURCE_PAGE_BUILDER = 'page-builder'
export const BUILDER_RESOURCE_PAGE_STRAPI = 'page-strapi'

export const CRM_REDIRECT_PARAMS_MAPPING_VALUES = {
  redirectToCleanserPDP: '/personalized-face-cleanser/',
  redirectToMoisturizerPDP: '/personalized-day-moisturizer/',
  redirectToNightCreamPDP: '/personalized-night-cream/',
  redirectToEyeDuoPDP: '/personalized-eye-cream-duo/',
  redirectToDayEyeCreamPDP: '/personalized-day-eye-cream/',
  redirectToNightEyeCreamPDP: '/personalized-night-eye-cream/',
  redirectToSerumPDP: '/account/shop/serum/',
  redirectToShopPage: '/account/shop/',
  redirectToAccountPage: '/account/',
  redirectTo422: '/account/shop/onetime/hydration-boost-day-moisturizer/',
  redirectToPaymentUpdatePage: '/account/settings/info/payment',
  redirectToSpotTreatmentPage: '/spot-treatment-serum/'
}

/* The style A is the default style for slider's arrows.
   The style B is the new one with circle around the arrow and positioned below the slides
   This constant values are used at Builder.io to choose the arrow style
 */
export const ARROW_VERSION_STYLE_A = 'slider-arrow-style-A'
export const ARROW_VERSION_STYLE_B = 'slider-arrow-style-B'

//To avoid showing insignificant discounts sometimes caused by price rounding errors etc. we use this threshold to decide whether to display them or not. The values are expressed in CENTS
//Example1: if the discount is $0,80 dollars, in cents is 80, then 80>10, so we WILL display this discount.
//Example2: if the discount is $0,07 dollars, in cents is 7, then 7<10, so we WILL NOT display this discount.
//Note: we are using the same threshold for pounds and euros. In the future we might use a custom threshold for every currency
export const INSIGNIFICANT_DISCOUNT_CENTS = 10
